<template>
  <div :class="containerClasses">
    <div class="form-box">
      <transition-group :duration="500">
        <div
          v-if="success"
          key="success"
          class="layer success-layer response-layer"
        >
          <div class="title">
            <h1>{{ $t('password_reset_confirmation.title') }}</h1>
          </div>
          <p class="spaced">
            {{ $t('password_reset_confirmation.intro_text') }}
          </p>
          <router-link
            :to="{ name: 'login' }"
            class="button is-success"
          >
            {{ $t('global.login') }}
          </router-link>
        </div>
        <div
          v-else-if="tokenError"
          key="error"
          class="layer error-layer response-layer"
        >
          <div class="title">
            <h1>{{ $t('password_reset_confirmation.invalid_link_title') }}</h1>
          </div>
          <p class="spaced">
            {{ $t('password_reset_confirmation.invalid_link_text') }}
          </p>
          <router-link
            :to="{ name: 'login' }"
            class="button is-success"
          >
            {{ $t('global.back') }}
          </router-link>
        </div>
        <div
          v-else
          key="form"
          class="layer form-container"
        >
          <div class="title">
            <h1 class="title-initial">
              {{ $t('password_reset_confirmation.form_title') }}
            </h1>
          </div>
          <form
            class="form"
            novalidate
            @submit.prevent="submitPasswordRecoveryConfirmation"
          >
            <ValidationObserver ref="observer">
              <ValidationProvider
                v-slot="{ errors, invalid }"
                ref="password"
                rules="required|min:8|password:@confirmation"
              >
                <b-field
                  :label="$t('password_reset_confirmation.enter_new_password')"
                  :type="{ 'is-danger': submitted && invalid }"
                  :message="submitted ? errors : []"
                >
                  <b-input
                    v-model="password"
                    type="password"
                    size="is-medium"
                    icon="eye"
                    class="input-password"
                    :placeholder="$t('password_reset_confirmation.enter_password')"
                    :validation-message="$t('password_reset_confirmation.enter_valid_password')"
                    required
                    password-reveal
                    :disabled="loading"
                  />
                </b-field>
              </ValidationProvider>
              <ValidationProvider
                v-slot="{ errors, invalid }"
                ref="confirmation"
                rules="required|min:8"
                name="confirmation"
              >
                <b-field
                  :label="$t('password_reset_confirmation.confirm_your_password')"
                  :type="{ 'is-danger': submitted && invalid }"
                  :message="submitted ? errors : []"
                >
                  <b-input
                    v-model="confirmation"
                    type="password"
                    size="is-medium"
                    icon="eye"
                    class="input-password"
                    :placeholder="$t('password_reset_confirmation.enter_password')"
                    :validation-message="$t('password_reset_confirmation.enter_valid_password')"
                    required
                    password-reveal
                    :disabled="loading"
                  />
                </b-field>
              </ValidationProvider>
            </ValidationObserver>
            <b-notification
              v-if="networkError"
              type="is-danger"
              aria-close-label="Ocultar"
              role="alert"
            >
              {{ $t('errors.an_error_has_occurred_try_again') }}'
            </b-notification>
            <div class="field">
              <p class="control">
                <button
                  type="submit"
                  class="button is-success"
                  :class="{ 'loading': loading, 'success': success }"
                >
                  <template v-if="loading">
                    {{ $t('password_reset_confirmation.changing_password') }}
                  </template>
                  <template v-else>
                    {{ $t('global.confirm') }}
                  </template>
                </button>
              </p>
            </div>
          </form>
        </div>
      </transition-group>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate'
import { min, required } from 'vee-validate/dist/rules'
import vue from '../main'

extend('min', {
  ...min,
  message: vue.$t('password_validation.password_min_length_error')
})

extend('required', {
  ...required,
  message: vue.$t('validation.field_is_required')
})

extend('password', {
  params: ['target'],
  validate(value, { target }) {
    return value === target
  },
  message: vue.$t('password_validation.password_and_confirmation_dont_match')
})

const namespace = 'PasswordResetConfirmation'
const { mapState } = createNamespacedHelpers(namespace)

export default {
  name: 'PasswordResetConfirmationForm',
  components: {
    ValidationProvider,
    ValidationObserver
  },
  data() {
    return {
      password: '',
      confirmation: ''
    }
  },
  computed: {
    ...mapState({
      networkError: (state) => state.networkError,
      tokenError: (state) => state.tokenError,
      loading: (state) => state.loading,
      submitted: (state) => state.submitted,
      success: (state) => state.success
    }),
    containerClasses() {
      return [
        'form-container', 'simple-form',
        this.loading ? 'loading' : '',
        this.tokenError ? 'error' : '',
        this.success ? 'success' : ''
      ]
    }
  },
  beforeDestroy() {
    this.$store.commit('PasswordResetConfirmation/resetState')
  },
  methods: {
    async submitPasswordRecoveryConfirmation() {
      if (this.loading) return

      const validateResult = await this.$refs.observer.validate()

      if (!validateResult) {
        // Display validation errors
        this.$store.commit('PasswordResetConfirmation/submitted')
        return
      }

      const { uid, token } = this.$route.params

      // Exceptions are handled internally in the store and reflect automatically on the UI
      this.$store.dispatch(
        'PasswordResetConfirmation/confirmPasswordReset',
        { uid, token, password: this.password }
      )
    }
  }
}
</script>

<style lang='scss' scoped>
  @import '../scss/simple_form.scss';
</style>
